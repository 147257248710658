<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						@setFilters="setFilters"
					/>

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler } from '@/mixins';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.reviews.fetchedMeta,
			filters: state => state.reviews.filters,
			itemsLoading: state => state.reviews.isLoading,
			itemsSaving: state => state.reviews.isSaving,
			itemsList: state => state.reviews.itemsList
		}),

		itemsName() {
			return {
				one: 'Отзыв',
				mult: 'Отзывы'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: '№', sortable: true, width: 60 },
					/*{
						prop: 'product.category.title_ru',
						label: 'Категория',
						// sortable: true,
						// meta: { sortBy: 'product_title' }
					},*/
					{
						prop: 'product.title_ru',
						label: 'Товар',
						sortable: true,
						meta: { sortBy: 'product_title' }
					},
					{
						prop: 'message',
						label: 'Текст отзыва',
						meta: { cellClass: 'message-cell' }
					},
					/*{
						// prop: 'user',
						props: ['user_first_name', 'user_last_name'],
						label: 'Пользователь',
						sortable: true,
						meta: { emptyText: ' ' }
					},*/
					{
						prop: 'is_published',
						label: 'Опубликовано',
						width: 90,
						sortable: true,
						meta: {
							boolean: { trueVal: 'Да', falseVal: 'Нет' }
						}
					},
					{
						prop: 'rating',
						label: 'Оценка',
						sortable: true,
						width: 70
					},
					{
						prop: 'created_at',
						label: 'Дата',
						sortable: true,
						meta: {
							prepareValue: { method: 'cleanDateString' }
						}
					}
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			show_overlay: 'show_overlay',

			fetch_items: 'reviews/fetch_reviews',
			delete_item: 'reviews/delete_review',

			set_items: 'reviews/set_reviews',
			set_filters: 'reviews/set_reviews_filters'
		})
	}
};
</script>
